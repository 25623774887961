import Util from "@/utils/utils";
import { getList, getExport, getYxList, getStatusList } from "./api";
export default {
  name: "terminalInspectionRecord",
  data() {
    return {
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/boothStoreExpenses",
          name: "展台门店需求",
          title: "展台门店需求"
        }
      ],
      visibleshop: false,
      hideOrShowSize: true,
      columns: [
        {
          label: "立项组织",
          prop: "orgName",
          width: 200,
        },
        {
          label: "需求提报单号",
          prop: "supplyCode",
          width: 200,
        },
        {
          label: "需求项目名称",
          prop: "projectSupplyName",
          width: 200,
        },
        {
          label: "计划项目名称",
          prop: "projectPlanText",
          width: 200,
        },
        {
          label: "门店名称",
          prop: "shops",
          width: 200,
        },
        {
          label: "物料组",
          prop: "matkls",
          width: 200,
        },
        {
          label: "提报人",
          prop: "creator",
          width: 200,
        },
        {
          label: "提报时间",
          prop: "submitDateStr",
          width: 200,
        },
        {
          label: "流程状态",
          prop: "checkStatus",
          width: 200,
        },
        {
          label: "驳回原因",
          prop: "rejectReason",
          width: 200,
        },
        {
          label: "操作",
          slot: "btnClick",
          width: 100,
          fixed: 'right',

        },

      ],
      tableData: [],
      columnsAll:[],// 记录所有列数据 调整列入参用
      pager: {
        pageNo: 1,
        pageSize: 10,
        count: 10
      },
      ssearchList: {

      },
      searchNumber: 0,
      hideshow: false,
      isLoading: false,
      onluyOneMessage: false,
      imgURL: [],
      tableLoading: false,
      marketCenterList: [], // 营销中心数据
      marketCenterName: '', //营销中心
      statusList: [], // 审批状态数据
      statusName: '', //审批状态
      statusName1: '', // 状态数据
      statusList1: ["申请中", "已申请", "核销中", "已核销", "作废",],
      selectedRowKey: [],
      selectedRowKeys: '',
      rowDta: '',
      mesageInfoTips: '确定删除该条数据？',
      selsectRow: {},
      aaaaa: false,
      fileList: [],
      fileIds: [],
      BUTTONloading:false,
      Canbutton: true,
      params:{}
    };
  },
  watch:{
    fileIds: {
      immediate: true,
      handler(newValue,oldValue) {
        if(this.fileIds.length>0) {
          this.Canbutton = false
        } else  {
          this.Canbutton = true
        }
      }
    }
  },
  created() {
    this.columnsAll = this.columns;
  },
  mounted() {
    // this.tablelist();
    // 获取营销模式数据u
    this.getYYXT()
    this.getStatus()
  },
  beforeCreate() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#ffffff");
  },
  beforeDestroy() {
    document.querySelector("body").setAttribute("style", "");
  },
  methods: {
    initTableColumns(tableColumns) {
      this.tcolumns = tableColumns.filter(j=>j.isShow === '1');
    },
    // 跳转页面()
    add(i, row) {
      // 编辑按钮如果选择数据为0 弹出提示
      if (i == 'edit') {
        // 编辑按钮符合条件跳转页面
        let routeUrl = this.$router.resolve({
          path: "/boothStoreExpenses/boothStoreAddAndEdit",
          query: {
            type: i,
            id: row.id
          },
        });
        window.open(routeUrl.href, "_blank");
        return;
      }
      if (i == 'add') {
        let routeUrl = this.$router.resolve({
          path: "/boothStoreExpenses/boothStoreAddAndEdit",
          query: {
            type: i,
          },
        });
        window.open(routeUrl.href, "_blank");
      }
      // 查看
      if(i == 'openView') {
        let routeUrl = this.$router.resolve({
          path: "/boothStoreExpenses/boothStoreView",
          query: { id: row.id },
        });
        window.open(routeUrl.href, "_blank");
      }

    },
    // 点击搜索进行数据查询(配置的筛选条件)
    searchFun(arg) {
      this.ssearchList = {
        ...this.pager.pageNo,
        ...this.pager.pageSize,
        ...this.ssearchList,
        ...arg[0]
      };
      this.tablelist();
    },

    // 时间更改
    handleTimeChange(e, defaultTime) {
      this.ssearchList.timeStart = defaultTime;
    },
    // 获取营销系统数据
    getYYXT() {
      let data = {}
      getYxList(data).then(res => {
        this.marketCenterList = res.data.data
      })
    },
    // 审批状态数据
    getStatus() {
      let data = {}
      getStatusList(data).then(res => {
        this.statusList = res.data.data
      })
    },
    // 更改
    handleChangeshopList(value, e) {
      this.marketCenterName = value;
      this.ssearchList.marketCenter = e.key;
    },
    // 更改
    handleChangestatuspList(value, e) {
      this.statusName = value;
      this.ssearchList.checkStatus = e.key;
    },
    // 更改
    handleChangestatuspList1(value, e) {
      this.statusName1 = value;
      this.ssearchList.status = e.key;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    fun_date(num) {
      var date1 = new Date();
      var yuefen =
        date1.getMonth() + 1 < 10
          ? "0" + Number(date1.getMonth() + 1)
          : date1.getMonth();
      var ri =
        date1.getDate() + 1 < 10 ? "0" + date1.getDate() : date1.getDate();
      var time1 = date1.getFullYear() + "-" + yuefen + "-" + ri;
      this.ssearchList.checkEnd = time1;

      var date2 = new Date(date1);
      date2.setDate(date1.getDate() + num);
      var yu =
        date2.getMonth() + 1 < 10
          ? "0" + Number(date2.getMonth() + 1)
          : date2.getMonth();
      var riq =
        date2.getDate() + 1 < 10 ? "0" + date2.getDate() : date2.getDate();
      var time2 = date2.getFullYear() + "-" + yu + "-" + riq;
      this.ssearchList.checkStart = time2;
    },
    exportClick() {
      let data = this.ssearchList;
      this.isLoading = true;
      getExport(data)
        .then(res => {
          this.isLoading = false;
          Util.blobToJson(res.data)
            .then(content => {
              if (content && content.msg == "success") {
                this.$message.success("下载成功，请到下载中心查看!");
              }
            })
            .catch(err => {
              Util.downloadFile(res.data, "代理商市场活动列表.xls");
            })
            .finally(() => {
              this.expLoading = false;
            });
        })
        .finally(res => {
          this.isLoading = false;
        });
    },
    loadMore() {
      this.hideshow = !this.hideshow;
    },
    pageSearch(pager) {
      this.ssearchList.pageNo = pager.pageNo;
      this.ssearchList.pageSize = pager.pageSize;
      this.pager.pageNo = pager.pageNo
      this.pager.pageSize = pager.pageSize
      this.tablelist();
    },
    tablelist() {
      let data = this.ssearchList;
      this.tableLoading = true
      getList(data).then(res => {
        if (res.data.code == 0) {
          this.tableData = res.data.list;
          if(res.data.list && res.data.list.length > 0) {
            this.tableData.forEach(item=>{
              item.orgName = item.orgDto.name
            })
          }

          this.searchNumber = res.data.totalRows;
          this.pager.count = res.data.totalRows;
          this.tableLoading = false
        } else {
          this.$message.warning(res.data.msg)
          this.tableLoading = false
        }

      });
    },


    // 查看详情
    toDetail(row) {
      let routeUrl = this.$router.resolve({
        // path: "/center/terminalInspectionRecord/terminalInspectiondetail",
        query: { id: row.id },
      });
      window.open(routeUrl.href, "_blank");
    },

    hideOrShow(data) {
      this.hideOrShowSize = data;
    },


    // 分页
    pagesiezes(data, pageSize) {
      this.ssearchList.pageNo = data;
      this.ssearchList.pageSize = pageSize;
      this.tablelist();
    },

    searchClick() {
      this.tablelist();
    }
  }
};
